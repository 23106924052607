import React, { useEffect, useRef, useState } from "react"
import intl from "react-intl-universal"

const OutlineContent = ({ parsedData, expanded }: { parsedData: any, expanded: boolean }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div className={`w-full text-black text-sm ${!isHovered && !expanded ? 'pr-6' : 'pr-4'} ${expanded ? 'h-screen': 'h-[420px]'} scrollable-div`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        overflowY: "auto",
        scrollbarWidth: !isHovered ? 'none' : undefined,
        msOverflowStyle: !isHovered ? 'none' : undefined,
      }}>
      <div><strong>{intl.get('gnowbe_ai_title_of_program')}:</strong> {parsedData.title}</div>
      <br />
      {parsedData.sessions.map((session, index) => {
        if (index === 0) return null
        return <div key={index}>
          <div><strong>{session.sessionNumber}</strong></div>
          <div><strong>{intl.get('title')}:</strong> {session.title}</div>
          <div><strong>{intl.get('global_description')}:</strong> {session.description}</div>
          <br />
        </div>
      })}
    </div>
  )
}

export default OutlineContent