import { Button } from 'components/utils/Button';
import React from 'react';

const ExitMagicCreatorAlert = ({onContinue, closeModal}) => {
    return ( 
        <div className="text-center">
            <img src="/images/magic-alert.svg" className="w-20 h-20 mx-auto mb-10" />
            <div className="font-bold text-lg mb-4">Are you sure you want to exit Magic Creator?</div>
            <div>If you leave now, your progress in creating a program using Magic Creator will be lost.</div>
            <div className="flex flex-row justify-center gap-x-3 mt-10">
                <Button text="Exit Magic Creator" onClick={onContinue} />
                <Button text="Continue Editing" type="magic" onClick={closeModal} />
            </div>
        </div>
     );
}
 
export default ExitMagicCreatorAlert;