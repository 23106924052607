import { ChevronDownIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import StepTitle from "./StepTitle"
import MagicCreditsIcon from "components/icons/MagicCredits"
import { languages, useCases, voice } from "../constants"
import { capitalize } from "utils/functions"
import intl from "react-intl-universal"

const ProgramSummaryField = ({ outLineData, uploadData, style, settings, } : { outLineData?: any, uploadData?: any, style?: any, settings?: any }) => {
  const [showDetails, setShowDetails] = useState(false)

  function removeLastUnderscoreSuffix(filename) {
    return filename.replace(/_(?!.*_)([^._]+)(\.[^.]*)$/, '$2')
  }

  const displayFilePrecision = (filePrecision) => {
    switch (filePrecision) {
      case 'high_precision':
        return intl.get('high_precision')
      case 'low_precision':
        return intl.get('magic_creative_exploration')
      default:
        return intl.get('high_precision')
    }
  }

  function getValueByKey(key, obj) {
    if (obj.hasOwnProperty(key)) {
        return obj[key]
    }
    return ''
}

  function getImageStatus(generateAiImages) {
    if (generateAiImages === true) {
      return intl.get('gnowbe_ai_with_generated_images')
    } else {
      return intl.get('gnowbe_ai_no_images')
    }
  }

  function getEmojiStatus(useEmoji) {
    if (useEmoji === true) {
      return intl.get('gnowbe_ai_with_generated_emojis')
    } else {
      return intl.get('gnowbe_ai_no_emojis')
    }
  }


  return (
    <div className={`flex flex-row items-start justify-start sm:items-center sm:justify-between bg-[#F9F8F8] rounded-lg p-4 relative ${settings ? 'mb-0' : 'mb-5'}`}>
      <div className="w-0 sm:w-1/4 font-bold">
        <div className="absolute top-4 left-4">
          {outLineData && `${intl.get('global_content')}: (${outLineData.sessions.length - 1} ${intl.get('gnowbe_ai_sessions')})`}
          {uploadData && `${intl.get('gnowbe_ai_uploaded_files')}: (${uploadData.length} ${intl.get('gnowbe_ai_files')})`}
          {style && intl.get('gnowbe_ai_style')}
          {settings && intl.get('settings_text')}
        </div>
      </div>
      <div className="mt-10 sm:mt-0 w-auto sm:w-2/3">
        {/* outline data */}
        {outLineData && <div>
          <div>{intl.get('title')}: {outLineData.title}</div>
          {outLineData.sessions.slice(0, 4).map((session, index) => {
            if (index === 0) return null
            return <div key={index}>
              <div>{session.sessionNumber} {session.title}</div>
            </div>
          })}
          {outLineData.sessions.length > 4 && (
            <div className="transition-all duration-300 ease-in-out overflow-hidden">
              {showDetails && (
                outLineData.sessions.slice(4).map((session, index) => (
                  <div key={index + 4}>
                    <div>{session.sessionNumber} {session.title}</div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>}
        {/* Upload data */}
        {uploadData && <div className="flex flex-col gap-2">
          {uploadData.slice(0, 3).map((file, index) => {
            return <div key={index}>
              <div>{removeLastUnderscoreSuffix(file.displayName)} ({displayFilePrecision(file.filePrecision)})</div>
            </div>
          }
          )}
          {uploadData.length > 3 && (
            <div className="transition-all duration-300 ease-in-out overflow-hidden">
              {showDetails && (
                uploadData.slice(3).map((file, index) => (
                  <div key={index + 3}>
                    <div>{removeLastUnderscoreSuffix(file.displayName)} ({displayFilePrecision(file.filePrecision)})</div>
                  </div>
                ))
              )}
            </div>)}
        </div>}
        {/* Style data */}
        {style && <div>
          <div>{intl.get('gnowbe_ai_tone_of_voice')}: {getValueByKey(style.voice, voice) || 'Automatic'}</div>
          <div>{intl.get('gnowbe_ai_target_audience')}: {capitalize(style.audience === 'Other' ? style.audienceOther : style.audience)}</div>
          <div>{intl.get('magic_use_case')}: {getValueByKey(style.useCase, useCases) || 'Automatic'}</div>
        </div>}
        {/* Style data */}
        {settings && <div>
          <div>{intl.get('magic_language')}: {getValueByKey(settings.language, languages) || 'Automatic'}</div>
          <div>{getImageStatus(settings.generateAiImages)}</div>
          <div>{getEmojiStatus(settings.useEmoji)}</div>
        </div>}
      </div>
      <div className="w-auto">
        {<ChevronDownIcon className={`absolute top-5 right-10 h-5 ml-2 transform transition-transform duration-300 ease-in-out ${(style || settings) || (outLineData && outLineData.sessions.length - 1 <= 3) ? 'hidden' : ''} ${showDetails ? 'rotate-180' : ''}`} onClick={() => setShowDetails(!showDetails)}/>}
      </div>
    </div>
  )
}

const ProgramFinalCosts = ({credits}) => {
  return ( <div className="mt-6 p-5 rounded-lg bg-white">
    <StepTitle title="Final Program Costs" description='' />
    <div className="flex gap-2" data-test="credits-information">
      <div className="flex items-center justify-center">
        <MagicCreditsIcon className="w-10 h-10 fill-magic" />
      </div>
      <div>
        <div className="font-bold">
          {intl.get('gnowbe_ai_total_cost')}: {credits.totalCredits}
        </div>
        <div>
          {intl.get('gnowbe_ai_you_have')} {credits.availableCredits} {intl.get('gnowbe_ai_credits_available')}
        </div>
      </div>
    </div>
  </div>
  )
}

export { ProgramSummaryField, ProgramFinalCosts }