/* eslint-disable react/display-name */
import React, { Suspense, memo } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Home } from 'pages/Home'
import { Learn } from 'pages/Learn'
import Login from 'components/auth/Login'
import Bot from 'components/bot/Bot'
import FullscreenLoader from 'components/utils/FullscreenLoader'
import Gnowbe2PreviewScreen from 'components/home/Gnowbe2PreviewScreen'
import { useQuery } from '@apollo/client'
import { GetOrganizationRes, getOrganizationQuery } from 'graphql/queries/organization/getOrganization'
import { useActiveWorkspaceId } from 'hooks/useActiveWorkspaceId'
import { OrganizationFeatureFlag } from 'graphql/schemas/organization/Organization'

const ImportRoute = React.lazy(
  () => import(/* webpackChunkName: "Import" */ '../components/editor/Import')
)
// n.d.
export const HasOrganizationFeatures: React.FC<{
  features: OrganizationFeatureFlag[],
  navigateTo?: string|null,
  children: React.JSX.Element,
}> = ({ features, navigateTo, children }) => {
  const activeWorkspaceId = useActiveWorkspaceId()
  const { data: orgData, loading, error } = useQuery<GetOrganizationRes>(getOrganizationQuery([]), {
    variables: { organizationId: activeWorkspaceId || '' },
    skip: !activeWorkspaceId,
  })

  if (loading) return <FullscreenLoader />
  if (error) return <Navigate to="/home" />

  const hasFeature = orgData?.res.featureFlags?.some(feature => features.includes(feature)) || false

  return hasFeature
    ? <>{children}</>
    : navigateTo
      ? <Navigate to={navigateTo} />
      : null
}

const RouteDefinitions = memo(() => {
  return (
    <Suspense fallback={<FullscreenLoader />}>
      <Routes>
        <Route path="/auth" element={<Navigate to="auth/login" /> } />
        <Route path="/auth/login" element={<Login / >} />

        <Route path="/edit/:companyIdCourseId" element={<Login / >} />

        <Route path="/home" element={<Home / >} />
        <Route path="/home/archive" element={<Home / >} />
        <Route path="/home/groups" element={<Home / >} />
        <Route path="/home/programs" element={<Home / >} />
        <Route path="/home/explore" element={<Home / >} />
        <Route path="/home/publish" element={<Home / >} />
        <Route path="/home/search" element={<Home / >} />
        <Route path="/home/search/:query" element={<Home / >} />

        <Route path="/details/:courseId" element={<Home / >} />
        <Route path="/details/:courseId/bundle/:bundleId" element={<Home / >} />

        <Route path="/credits" element={<Home / >} />
        <Route path="/settings" element={<Home / >} />

        <Route path="/chat" element={<Home / >} />
        <Route path="/chat/:urn" element={<Home / >} />
        <Route path="/curatorChat" element={<Home / >} />
        <Route path="/curatorChat/:urn" element={<Home / >} />

        <Route path="/activity" element={<Home / >} />

        <Route path="/profile" element={<Home / >} />
        <Route path="/profile/:userId" element={<Home / >} />

        {/* legacy urls */}
        <Route path="/session" element={<Learn / >} />
        <Route path="/session/:chapterId" element={<Learn / >} />
        <Route path="/group" element={<Learn / >} />
        <Route path="/notes" element={<Learn / >} />
        <Route path="/chatbot" element={<Learn / >} />
        <Route path="/workbook" element={<Learn / >} />
        <Route path="/workbook/progress" element={<Learn / >} />

        <Route path="/learn" element={<Learn / >} />
        <Route path="/learn/notes" element={<Learn / >} />
        <Route path="/learn/chatbot" element={<Learn / >} />

        <Route path="/learn/group" element={<Learn / >} />
        <Route path="/learn/share/:shareId" element={<Learn / >} />
        <Route path="/learn/likes/:chapterId" element={<Learn / >} />
        <Route path="/learn/likes/:chapterId/:actionId" element={<Learn / >} />
        <Route path="/learn/likes/:chapterId/:actionId/:userId" element={<Learn / >} />
        <Route path="/learn/shares/:memberId" element={<Learn / >} />

        <Route path="/learn/members" element={<Learn / >} />
        <Route path="/learn/workbook" element={<Learn / >} />
        <Route path="/learn/progress" element={<Learn / >} />
        <Route path="/learn/resources" element={<Learn / >} />
        <Route path="/learn/feedback" element={<Learn / >} />

        <Route path="/learn/:chapterId" element={<Learn / >} />
        <Route path="/learn/:chapterId/:actionId" element={<Learn / >} />
        <Route path="/learn/:chapterId/:actionId/r" element={<Learn / >} />

        <Route path="/import" element={<ImportRoute / >} />

        {/* botAi feature */}
        <Route path="/preview" element={
          <HasOrganizationFeatures
            navigateTo={'/home'}
            features={['botAi']}
          >
            <Gnowbe2PreviewScreen />
          </HasOrganizationFeatures>}
        />
        <Route path="/preview/bot" element={
          <HasOrganizationFeatures
            navigateTo={'/home'}
            features={['botAi']}
          >
            <Bot />
          </HasOrganizationFeatures>}
        />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </Suspense>
  )
})

export default RouteDefinitions
