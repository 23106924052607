import React from "react";
import { Transition, Dialog, DialogBackdrop, TransitionChild } from "@headlessui/react"
import { useState, useEffect } from "react"
import { Fragment } from "react/jsx-runtime"
import ExitMagicCreatorAlert from "./gnowbeAi/components/ExitMagicCreatorAlert"
import { showModal2 } from "./utils/CustomModal"
import classNames from "classnames"

const Drawer = ({ onClose, alertBeforeClose, fromRight, maxWidth, children }) => {
  const [show, setShow] = useState(true)
  const [tShow, setTshow] = useState(true)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => document.body.classList.remove('modal-open')
  }, [])


  const close = () => {
    if (alertBeforeClose === 'magic') {
      const modal = showModal2({
        component: (
          <ExitMagicCreatorAlert
            onContinue={() => {
              setShow(false)
              setTimeout(() => setTshow(false), 300)
              modal.close()
              onClose()
            }}
            closeModal={() => modal.close()}
          />
        ),
        onlyContent: true,
      })
    } else {
      setShow(false);
      setTimeout(() => setTshow(false), 300)
      onClose()
    }
  };

  if (!tShow) return null

    const mainDivCn = classNames({
      'left-0 pr-10': !fromRight,
      'right-0 pl-10': fromRight,
      'fixed inset-y-0 max-w-full flex': true,
    })
  
    const enterFromLeaveTo = fromRight ? 'translate-x-full' : '-translate-x-full'
    const enterToLeaveFrom = fromRight ? '-translate-x-0' : 'translate-x-0'

  return (
    <Transition show={show} appear={true} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-hidden" style={{ zIndex: 1060 }} open={true} onClose={close}>
          <div className="absolute inset-0 overflow-hidden">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <DialogBackdrop className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" onClick={close} />
            </TransitionChild>

            <div className={mainDivCn}>
              <TransitionChild
                as={Fragment}
                enter="transition ease-in-out duration-500 sm:duration-700"
                enterFrom={enterFromLeaveTo}
                enterTo={enterToLeaveFrom}
                leave="transition ease-in-out duration-500 sm:duration-700"
                leaveFrom={enterToLeaveFrom}
                leaveTo={enterFromLeaveTo}>
                <div className={`w-screen ${maxWidth || 'max-w-md'} h-full`}>
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    

                    <section className="flex-1 h-0 overflow-y-auto">
                      {children}
                    </section>

                    
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
  );
};
  
  export default Drawer;
  