import Drawer from "components/DrawerNew";
import React, { useContext, useState } from "react";
import { createContext } from "react";

type DrawerProps = {
    id?: string;
    component?: any;
    header?: {
      title?: string|JSX.Element;
      subtitle?: string;
      bg?: string;
      hide?: boolean;
    };
    footer?: {
      reverse?: boolean;
      hide?: boolean;
    };
    primaryButton?: {
      text?: string;
      onClick?: () => void;
    };
    secondaryButton?: {
      text?: string;
      onClick?: () => void;
    };
    children?: any;
    maxWidth?: string;
    fromRight?: boolean;
  
    onClose?: () => void;
    alertBeforeClose?: boolean;
  }

const DrawerContext = createContext<{
  showDrawer: (props: DrawerProps) => void;
  currentStep: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  closeDrawer: () => void;
}>({
  showDrawer: () => {},
  currentStep: '',
  setCurrentStep: () => {},
  closeDrawer: () => {},
});

export const DrawerProvider = ({ children }) => {
  const [drawerProps, setDrawerProps] = useState<DrawerProps | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState('');

  const showDrawer = (props: DrawerProps) => {
    setDrawerProps(props);
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
    setCurrentStep('');
  };

  return (
    <DrawerContext.Provider value={{ showDrawer, currentStep, setCurrentStep, closeDrawer }}>
      {children}
      {isOpen && drawerProps && (
        <Drawer
          {...drawerProps}
          alertBeforeClose={currentStep ?? ''}
          fromRight={drawerProps?.fromRight ?? false}
          maxWidth={drawerProps?.maxWidth ?? "500px"}
          onClose={() => {
            drawerProps?.onClose && drawerProps.onClose();
            closeDrawer();
          }}
        >
          {drawerProps.component}
        </Drawer>
      )}
    </DrawerContext.Provider>
  );
};

// Hook to use `showDrawer` inside components
export const useDrawer = () => {
  return useContext(DrawerContext);
};